export default [
  {
    id: "marker1",
    lat: 37.57253590000001,
    lng: 127.0153043,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/OpA3K-2GELA?si=x0jhFB5YUNBIUIkx",
        location: "곽상언 선거사무소 (지봉로 29)(3/4)",
        cont: "서울종로 곽상언 개소식",
      },
    ],
  },
  {
    id: "marker2",
    lat: 37.5731806,
    lng: 127.011421,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/r5Ov0bxkAGQ?si=FkM3AzYC0htZksKI",
        location: "창신시장(3/4)",
        cont: "서울종로 민생현장",
      },
    ],
  },
  {
    id: "marker3",
    lat: 37.5223245,
    lng: 126.9101692,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Ts_Hp5boU2w?si=fIFNvCrA2XmRZZpf",
        location: "영등포 뉴포트빌딩(3/5)",
        cont: "서울 영등포갑 채현일 개소식",
      },
    ],
  },
  {
    id: "marker4",
    lat: 37.5154133,
    lng: 126.9071288,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "https://www.youtube.com/live/-rRNj83U0GM?",
        location: "영등포 기자회견 (영등포역 앞)(3/5)",
        cont: "서울 영등포갑 기자회견",
      },
    ],
  },
  {
    id: "marker5",
    lat: 37.5374507,
    lng: 126.7375494,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/ky9ELVrj1a8?si=Oc_P7MOlT2_nqimz",
        location: "계양 게릴라 (계양구보건소 앞)(3/5)",
        cont: "인천 계양을 민생현장",
      },
    ],
  },
  {
    id: "marker6",
    lat: 37.5270381,
    lng: 126.870737,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/-K_dXjyDqYM?si=4AhNvrAZ1qmPBm_3",
        location: "목동예술인회관(3/6)",
        cont: "서울 양천갑 황희 개소식",
      },
    ],
  },
  {
    id: "marker7",
    lat: 37.5490041,
    lng: 126.8645175,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/-K_dXjyDqYM?si=4AhNvrAZ1qmPBm_3",
        location: "목동깨비시장(3/6)",
        cont: "서울 양천갑 민생현장",
      },
    ],
  },
  {
    id: "marker8",
    lat: 37.5180465,
    lng: 127.5792445,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/jQyxFQG-pDc?si=2YBx8QkBGgOxIvjs",
        location: "양평고속도로(3/7)",
        cont: "양평고속도로의혹",
      },
    ],
  },
  {
    id: "marker9",
    lat: 37.4916785,
    lng: 127.4874383,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "",
        location: "양평군청(3/7)",
        cont: "경기 여주양평 최재관후보 지지유세",
      },
    ],
  },
  {
    id: "marker10",
    lat: 37.545075,
    lng: 126.738653,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Ti10esbGfEw?si=ugB6HYiPbQv17Y87",
        location: "임학역(3/8)",
        cont: "인천 계양을 민생현장",
      },
    ],
  },
  {
    id: "marker11",
    lat: 37.5309564999999,
    lng: 126.7365231,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/vCa8PBE0Fz4?si=IPO_P8T4PT4VBMp-",
        location: "작전서운동(3/9)",
        cont: "인천 계양을 민생현장",
      },
    ],
  },
  {
    id: "marker12",
    lat: 37.5312613,
    lng: 126.7230391,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/7jZKTO7RAVQ?si=Cubhr7GjVvDosmQJ",
        location: "덕영빌딩(3/9)",
        cont: "인천 계양갑 유동수 개소식",
      },
    ],
  },
  {
    id: "marker13",
    lat: 36.6009247,
    lng: 126.669887,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/O_JJSGH2ucs?si=nSmtyP2lG47zTW7c",
        location: "홍성시장(3/11)",
        cont: "충남 홍성 민생현장",
      },
    ],
  },
  {
    id: "marker14",
    lat: 36.5990886,
    lng: 126.6658325,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "",
        location: "충남 연석회의(3/11)",
        cont: "충남 후보 연석회의 및 필승결의대회",
      },
    ],
  },
  {
    id: "marker15",
    lat: 36.8191809,
    lng: 127.1530085,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Dlqy3WWkUmE?si=PFvpwiYe7wdoK5cw",
        location: "충남도당 회의실(3/11)",
        cont: "충남도당 정책간담회",
      },
    ],
  },
  {
    id: "marker16",
    lat: 36.8180634,
    lng: 127.1561576,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/EljWe7QjbQ4?si=B4nOuU9zY0rhPeFr",
        location: "신부동문화공원(3/11)",
        cont: "충남 천안 기자회견",
      },
    ],
  },
  {
    id: "marker17",
    lat: 37.4883121,
    lng: 126.9819352,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/YqVi2MIbXjk?si=FwBQ1Z_mW0gICkh6",
        location: "동작 게릴라 (동작대로 129)(3/12)",
        cont: "서울 동작을 류삼영 지지유세 (게릴라)",
      },
    ],
  },
  {
    id: "marker18",
    lat: 37.5326216,
    lng: 126.6434447,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/wsLfZ4HKcwo?si=FBk4Ori4Lp8Pop3r",
        location: "청라 커널웨이(3/12)",
        cont: "인천 서구을 이용우 깜짝응원",
      },
    ],
  },
  {
    id: "marker19",
    lat: 37.4247618,
    lng: 126.6474538,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "",
        location: "옥련시장(3/12)",
        cont: "인천 연수갑 박찬대 깜짝응원",
      },
    ],
  },
  {
    id: "marker20",
    lat: 37.538806151154,
    lng: 126.738053984314,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/svN1QAAk5eY?si=vOI4MOehF40_FJoz",
        location: "계양먹거리골목(3/12)",
        cont: "인천 계양을 거리유세 (게릴라)",
      },
    ],
  },
  {
    id: "marker21",
    lat: 37.4837567,
    lng: 126.9731999,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "",
        location: "류삼영 선거사무소(3/13)",
        cont: "서울 동작을 류삼영 선거사무소",
      },
    ],
  },
  {
    id: "marker22",
    lat: 37.4880894,
    lng: 126.9805176,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/zxtPdg8Xa3M?si=XXPqApXz_OA2AFiu",
        location: "남성사계시장(3/13)",
        cont: "서울 동작 민생현장",
      },
    ],
  },
  {
    id: "marker23",
    lat: 37.4881837,
    lng: 126.9820907,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/zxtPdg8Xa3M?si=XXPqApXz_OA2AFiu",
        location: "동광약국(3/13)",
        cont: "서울 동작 기자회견",
      },
    ],
  },
  {
    id: "marker24",
    lat: 37.5361061,
    lng: 126.9601794,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/3GM374Kmhzk?si=UFasftJ3HHKpWMUV",
        location: "용문시장(3/13)",
        cont: "서울 용산 민생현장",
      },
    ],
  },
  {
    id: "marker25",
    lat: 37.539289,
    lng: 126.961339,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/3GM374Kmhzk?si=UFasftJ3HHKpWMUV",
        location: "효창공원앞역(3/13)",
        cont: "서울 용산 기자회견",
      },
    ],
  },
  {
    id: "marker26",
    lat: 37.557382,
    lng: 126.956083,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/HfA9iU-l0Cg?si=v_qu2aaD-scs1mcA",
        location: "서대문갑 게릴라 (아현역 앞)(3/13)",
        cont: "서울 서대문갑 지지유세 (게릴라)",
      },
    ],
  },
  {
    id: "marker27",
    lat: 36.3283766,
    lng: 127.4282393,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/SnQhEG_pza0?si=gW-65huRLE9d2-C2",
        location: "으능정이 거리 입구(3/14)",
        cont: "대전 민생현장",
      },
    ],
  },
  {
    id: "marker28",
    lat: 36.3279934,
    lng: 127.4286446,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/SnQhEG_pza0?si=gW-65huRLE9d2-C2",
        location: "대종로480번길 38(3/14)",
        cont: "대전 기자회견",
      },
    ],
  },
  {
    id: "marker29",
    lat: 36.5997673,
    lng: 127.3005954,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/ewMSF5gmODU?si=nwZKms_jtUYHQBna",
        location: "세종전통시장(3/14)",
        cont: "세종 민생현장",
      },
    ],
  },
  {
    id: "marker30",
    lat: 36.6278303,
    lng: 127.4884534,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/M-c2G8tbkaE?si=Ru3mCANzs7ggHf7j",
        location: "육거리종합시장(3/14)",
        cont: "충북 청주 민생현장",
      },
    ],
  },
  {
    id: "marker31",
    lat: 36.6346278,
    lng: 127.4884384,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/M-c2G8tbkaE?si=Ru3mCANzs7ggHf7j",
        location: "청주시청임시청사별관(3/14)",
        cont: "청주 오송참사 합동분향소 참배",
      },
    ],
  },
  {
    id: "marker32",
    lat: 36.6418947,
    lng: 127.4888954,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/KlhGCra8XAE?si=SUhcO6ZaiM-rTs-w",
        location: "청주시의회(3/14)",
        cont: "청주 오송참사 유가족 생존자 간담회",
      },
    ],
  },
  {
    id: "marker33",
    lat: 36.6281314999999,
    lng: 127.4563596,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/6QBRkh-fey4?si=soIsjkpYud7EwTKW",
        location: "충북대 중문(3/14)",
        cont: "충북대 중문 민생현장",
      },
    ],
  },
  {
    id: "marker34",
    lat: 36.6321056,
    lng: 127.457273,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/6QBRkh-fey4?si=soIsjkpYud7EwTKW",
        location: "쿼드커피 충북대(3/14)",
        cont: "충북 기자회견",
      },
    ],
  },
  {
    id: "marker35",
    lat: 35.5269662,
    lng: 129.3207713,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/hBPS3Lbk7N0?si=3VeUabOqL2YN47x3",
        location: "울산 수암시장(3/15)",
        cont: "울산 남구 민생현장",
      },
    ],
  },
  {
    id: "marker36",
    lat: 35.5249126,
    lng: 129.3201973,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/hBPS3Lbk7N0?si=3VeUabOqL2YN47x3",
        location: "남구 수암로 116번길 4(3/15)",
        cont: "울산 남구 기자회견",
      },
    ],
  },
  {
    id: "marker37",
    lat: 35.5088339,
    lng: 129.4254345,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/36yZvySCSl8?si=PyUqP-nqgG673vN6",
        location: "동울산종합시장 / 동구 진성5길 59(3/15)",
        cont: "울산 동구 민생현장",
      },
    ],
  },
  {
    id: "marker38",
    lat: 35.2429009,
    lng: 129.2156349,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/zd-KPANNSpw?si=cr-FowwuvEAOld1u",
        location: "부산 기장시장(3/15)",
        cont: "부산 기장군 민생현장",
      },
    ],
  },
  {
    id: "marker39",
    lat: 35.2443629,
    lng: 129.2157721,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/zd-KPANNSpw?si=cr-FowwuvEAOld1u",
        location: "명품대게 앞(기장읍 차성동로73번길 5)(3/15)",
        cont: "부산 기장군 기자회견",
      },
    ],
  },
  {
    id: "marker40",
    lat: 35.1640113,
    lng: 129.0356017,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/xmKns0NrPU4?si=j3IxL-ZqgJkagBBm",
        location: "부산 당감새시장(3/15)",
        cont: "부산 부산진구 민생현장",
      },
    ],
  },
  {
    id: "marker41",
    lat: 35.0761373,
    lng: 128.9697088,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/1pVLIEwyD-Q?si=jGMxfGZOIssWngUc",
        location: "부산 장림골목시장(3/15)",
        cont: "부산 사하구 민생현장",
      },
    ],
  },
  {
    id: "marker42",
    lat: 37.5380506,
    lng: 127.2060425,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/pfyYQRFayWY?si=xpOcbutwtJtRCbXj",
        location: "하남대로801번길 74(3/16)",
        cont: "경기 하남 기자회견",
      },
    ],
  },
  {
    id: "marker43",
    lat: 37.5379566,
    lng: 127.2063907,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/pfyYQRFayWY?si=xpOcbutwtJtRCbXj",
        location: "하남 신장시장 (3/16)",
        cont: "경기 하남 민생현장",
      },
    ],
  },
  {
    id: "marker44",
    lat: 37.32238,
    lng: 127.094447,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/ut8lavXZscw?si=YWBjdlfxTWPuJJrM",
        location: "용인 수지구청역(3/16)",
        cont: "경기 용인 민생현장",
      },
    ],
  },
  {
    id: "marker45",
    lat: 37.3334612,
    lng: 127.0715186,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/ut8lavXZscw?si=YWBjdlfxTWPuJJrM",
        location: "수지구청 광장(3/16)",
        cont: "경기 용인 기자회견",
      },
    ],
  },
  {
    id: "marker46",
    lat: 37.4082407,
    lng: 127.2562633,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/PQiySEltWd8?si=W0rtx928VrK9WLl2",
        location: "금호리첸시아 앞(3/16)",
        cont: "경기 광주 기자회견",
      },
    ],
  },
  {
    id: "marker47",
    lat: 37.4087883,
    lng: 127.257106,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/PQiySEltWd8?si=W0rtx928VrK9WLl2",
        location: "경기광주 경안시장(3/16)",
        cont: "경기 광주 민생현장",
      },
    ],
  },
  {
    id: "marker48",
    lat: 37.5478382,
    lng: 126.7384062,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/-rQaPjMxCrc?si=C9bXocq6dZfZ6ZLZ",
        location: "병방동 390(3/16)",
        cont: "계양 게릴라",
      },
    ],
  },
  {
    id: "marker49",
    lat: 37.1616306,
    lng: 126.8654604,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/xMrJSiKkBEs?si=RSKOhcnubr6E9FHx",
        location: "국회의원회관 대회의실(3/17)",
        cont: "22대총선후보자대회",
      },
    ],
  },
  {
    id: "marker50",
    lat: 37.1691623,
    lng: 127.1006911,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/ivIBME243xU?si=2m3ag4mcqtyjtNTw",
        location: "동탄호수공원 입구 광장(3/17)",
        cont: "경기 화성 기자회견",
      },
    ],
  },
  {
    id: "marker51",
    lat: 37.007136,
    lng: 127.2691792,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/gjU6WMYOOrU?si=w2AAlcAaBGO-Z56I",
        location: "안성 중앙시장(3/17)",
        cont: "경기 안성 민생현장",
      },
    ],
  },
  {
    id: "marker52",
    lat: 37.0159677,
    lng: 126.9941853,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Ploiflo_-Vo?si=yBFVgNlzXbgqNcSP",
        location: "퍙택 평택역앞 명동거리(3/17)",
        cont: "경기 평택 민생현장",
      },
    ],
  },
  {
    id: "marker53",
    lat: 36.9914138,
    lng: 127.0859353,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Ploiflo_-Vo?si=yBFVgNlzXbgqNcSP",
        location: "평택역광장 평택동 185-529(3/17)",
        cont: "경기 평택 기자회견 ",
      },
    ],
  },
  {
    id: "marker54",
    lat: 37.1695607,
    lng: 127.0654428,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/2PDYomVMQoc?si=q1aXqVMXQ9g-DcSx",
        location: "내삼미로 79번길 52(3/17)",
        cont: "경기 세교 오산대역 중심상가 방문",
      },
    ],
  },
  {
    id: "marker55",
    lat: 37.5294497,
    lng: 126.9194388,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/lgLkjB8CSIQ?si=C2JLw8SX6OoB0Dh0",
        location: "중앙당사 4층 대회의실(3/18)",
        cont: "-재외국민 투표독려 캠페인-제3차 중앙선거대책회의",
      },
    ],
  },
  {
    id: "marker56",
    lat: 37.5451941,
    lng: 126.9471063,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/5XADrZ2XhRw?si=0XwHGB09rky4wFcM",
        location: " 공덕파크자이 201동 앞(3/18)",
        cont: "마포 기자회견",
      },
    ],
  },
  {
    id: "marker57",
    lat: 37.5588868,
    lng: 126.9254274,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/5XADrZ2XhRw?si=0XwHGB09rky4wFcM",
        location: "마포 경의선숲길(3/18)",
        cont: "거리인사",
      },
    ],
  },
  {
    id: "marker58",
    lat: 37.8777803,
    lng: 127.7250992,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/qZRb4JaU4mA?si=VNjz3_BWZsr7xHRK",
        location: "춘천 중앙시장 및 명동거리(3/19)",
        cont: "춘천 거리인사",
      },
    ],
  },
  {
    id: "marker59",
    lat: 37.8812763,
    lng: 127.7300762,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/qZRb4JaU4mA?si=VNjz3_BWZsr7xHRK",
        location: "춘천시청 광장(시청길 11)(3/19)",
        cont: "기자회견",
      },
    ],
  },
  {
    id: "marker60",
    lat: 37.3499847,
    lng: 127.949244,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/0U4jRTuaCwY?si=bw-y59ZfK0C5OFlA",
        location: "원주 중앙시장 및 문화의거리(3/19)",
        cont: "원주 거리인사",
      },
    ],
  },
  {
    id: "marker61",
    lat: 37.2810267,
    lng: 127.4404642,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/N8prkN6NTVA?si=IeKqXaDKK6XY7-Bi",
        location: "중앙로 문화의 광장 (3/19)",
        cont: "이천 기자회견",
      },
    ],
  },
  {
    id: "marker62",
    lat: 37.2805541,
    lng: 127.4440487,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/N8prkN6NTVA?si=IeKqXaDKK6XY7-Bi",
        location: "이천 관고전통시장(3/19)",
        cont: "이천 거리인사",
      },
    ],
  },
  {
    id: "marker63",
    lat: 37.4305345,
    lng: 127.1298714,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/annE3kWvXkc?si=PSFgaCVcDodx-RS2",
        location: "다이소 성남모란점(3/19)",
        cont: "성남 중원 거리인사",
      },
    ],
  },
  {
    id: "marker64",
    lat: 37.432124,
    lng: 127.129064,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/annE3kWvXkc?si=PSFgaCVcDodx-RS2",
        location: "모란오거리 광장(3/19)",
        cont: "성남 수정중원 기자회견",
      },
    ],
  },
  {
    id: "marker65",
    lat: 37.4117151,
    lng: 127.1281315,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/yv-_5lV5CyU?si=c3ZwAGQLp6rf1FjP",
        location: "야탑광장(3/19)",
        cont: "성남 분당 기자회견",
      },
    ],
  },
  {
    id: "marker66",
    lat: 37.4120267,
    lng: 127.1302594,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/yv-_5lV5CyU?si=c3ZwAGQLp6rf1FjP",
        location: "성남 분당 야탑먹자골목(3/19)",
        cont: "성남 분당거리인사",
      },
    ],
  },
  {
    id: "marker67",
    lat: 37.5294497,
    lng: 126.9194388,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/_V1peU1Cou0?si=zJJEfMDBYv5cElbz",
        location: "중앙당사 4층 대회의실(3/20)",
        cont: "더민주 더민련 총괄선거대책위 합동회의",
      },
    ],
  },
  {
    id: "marker68",
    lat: 37.5311396,
    lng: 126.9170175,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/EqQsmqVkjmc?si=SiF5w03RSsmmP4Wr",
        location: "국회도서관 강당(3/20)",
        cont: "서울시당 선거대책위원회 출범식",
      },
    ],
  },
  {
    id: "marker69",
    lat: 37.4513889,
    lng: 126.6377778,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/0VsZR8XAPOw?si=33HIzV_Ju3viA90Y",
        location: "미추홀구 토지금고시장(3/20)",
        cont: "미추홀구 민생현장",
      },
    ],
  },
  {
    id: "marker70",
    lat: 37.4468491,
    lng: 126.6777689,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/GR5VK-Av9Jc?si=v2LzPIa1MgghGLvF",
        location: "미추홀구 신기시장(3/20)",
        cont: "미추홀구 민생현장",
      },
    ],
  },
  {
    id: "marker71",
    lat: 37.5185308,
    lng: 126.675928,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/wgbsL-RxiBw?si=EdOBuYAOJJj0ywpl",
        location: "서구 정서진중앙시장(3/20)",
        cont: "서구 민생현장",
      },
    ],
  },
  {
    id: "marker72",
    lat: 37.5184789,
    lng: 126.6726053,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/wgbsL-RxiBw?si=EdOBuYAOJJj0ywpl",
        location: "가정로 375(3/20)",
        cont: "서구 기자회견",
      },
    ],
  },
  {
    id: "marker73",
    lat: 37.503943,
    lng: 126.738937,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/QIdiJENjJEY?si=dNoNrkfA_zza97yQ",
        location: "부평 부개종합시장(3/20)",
        cont: "부평 민생현장",
      },
    ],
  },
  {
    id: "marker74",
    lat: 37.5076847,
    lng: 126.7361018,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "https://www.youtube.com/watch?v=Ihz1otV34sQ",
        location: "부평 삼산동 상가(3/20)",
        cont: "부평 민생현장",
      },
    ],
  },
  {
    id: "marker75",
    lat: 35.2353434,
    lng: 126.9401187,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/sVFMuDGJ_lw?si=EBqtSJJEeBvyAThY",
        location: "국립518민주묘지(3/21)",
        cont: "광주 518민주묘역참배",
      },
    ],
  },
  {
    id: "marker76",
    lat: 35.1468016,
    lng: 126.8405088,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/UTOT5NOtJh4?si=uNIdYhvxvE2vgfhJ",
        location: "김대중컨벤션센터(3/21)",
        cont: "광주 현장 선대위",
      },
    ],
  },
  {
    id: "marker77",
    lat: 35.1749443,
    lng: 126.9145003,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "https://www.youtube.com/watch?v=yaBOEQ7l8dI",
        location: "광주 전남대후문상가(3/21)",
        cont: "광주 민생현장",
      },
    ],
  },
  {
    id: "marker78",
    lat: 35.1762231,
    lng: 126.9057696,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/yaBOEQ7l8dI?si=W3ZMSYf9CBGh3iM5",
        location: "전남대 후문 광장(3/21)",
        cont: "광주 기자회견",
      },
    ],
  },
  {
    id: "marker79",
    lat: 35.9850103,
    lng: 126.7082952,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/GIyQpj0FpQ4?si=e3wu0VRl71uYFsvA",
        location: "고우당 앞(3/21)",
        cont: "군산 근대문화역사거리 인사",
      },
    ],
  },
  {
    id: "marker80",
    lat: 35.9877636,
    lng: 126.7116772,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/GIyQpj0FpQ4?si=e3wu0VRl71uYFsvA",
        location: "구시청광장 (중앙로1가 11-1)(3/21)",
        cont: "군산 기자회견",
      },
    ],
  },
  {
    id: "marker81",
    lat: 36.1946404,
    lng: 127.1047917,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/8lG9hi0biEs?si=Gcckgh0AxasTWrGo",
        location: "논산시민공원(3/21)",
        cont: "논산 딸기축제",
      },
    ],
  },
  {
    id: "marker82",
    lat: 36.1964253,
    lng: 127.1041161,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/8lG9hi0biEs?si=Gcckgh0AxasTWrGo",
        location: "논산보건소 뒷편 야외무대(3/21)",
        cont: "논산 기자회견",
      },
    ],
  },
  {
    id: "marker83",
    lat: 36.7819347,
    lng: 126.4578005,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/2md3zC1d5DI?si=zYCHYQxbm1YB_SHs",
        location: "서산 동부시장(3/22)",
        cont: "서산 거리인사",
      },
    ],
  },
  {
    id: "marker84",
    lat: 36.7824148,
    lng: 126.4553692,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/2md3zC1d5DI?si=zYCHYQxbm1YB_SHs",
        location: "서산 동부시장(3/22)",
        cont: "서산 기자회견",
      },
    ],
  },
  {
    id: "marker85",
    lat: 36.8917888,
    lng: 126.6295707,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/QONLIZ0C-ak?si=53ngQPebNMhBDjgi",
        location: "당진시장(3/22)",
        cont: "",
      },
    ],
  },
  {
    id: "marker86",
    lat: 36.7832262,
    lng: 127.0043687,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/361vBHmZs9Q?si=KXapPLThIvGp-aR1",
        location: "아산 온양온천시장(3/22)",
        cont: "아산 거리인사",
      },
    ],
  },
  {
    id: "marker87",
    lat: 36.7809052,
    lng: 127.0039785,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/361vBHmZs9Q?si=KXapPLThIvGp-aR1",
        location: "온천동 69-10(3/22)",
        cont: "아산 기자회견",
      },
    ],
  },
  {
    id: "marker88",
    lat: 37.8280638,
    lng: 127.1444379,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/zURhoW1kr08?si=RxZClW6WslW9qrfO",
        location: "소흘농협하나로마트(3/23)",
        cont: "포천 소흘읍 거리인사",
      },
    ],
  },
  {
    id: "marker89",
    lat: 37.8304751,
    lng: 127.1418492,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/zURhoW1kr08?si=RxZClW6WslW9qrfO",
        location: "태봉로166번길 7-27(3/23)",
        cont: "포천 기자회견",
      },
    ],
  },
  {
    id: "marker90",
    lat: 37.7397649,
    lng: 127.0503993,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Xn0nBoDw_mk?si=8Wg_rRH_BzYcexuV",
        location: "의정부 제일시장(3/23)",
        cont: "의정부 제일시장",
      },
    ],
  },
  {
    id: "marker91",
    lat: 37.7399777,
    lng: 127.0484924,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Xn0nBoDw_mk?si=8Wg_rRH_BzYcexuV",
        location: "의정부 행복로 11(3/23)",
        cont: "의정부 기자회견",
      },
    ],
  },
  {
    id: "marker92",
    lat: 37.7516255,
    lng: 126.7669376,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/hCX4f571X9k?si=BIaQ4W6M5U9dLm5M",
        location: "파주 금릉역 로데오거리(3/23)",
        cont: "파주 거리인사",
      },
    ],
  },
  {
    id: "marker93",
    lat: 37.7516085,
    lng: 126.7662138,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/hCX4f571X9k?si=BIaQ4W6M5U9dLm5M",
        location: "중앙광장 , 금촌동 986-3(3/23)",
        cont: "파주 기자회견",
      },
    ],
  },
  {
    id: "marker94",
    lat: 37.6405922,
    lng: 126.679891,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Ao98rcyyzPg?si=I4g3RupwVBFXj0iY",
        location: "김포 라베니체에비뉴(3/23)",
        cont: "김포 유세",
      },
    ],
  },
  {
    id: "marker95",
    lat: 37.6818227,
    lng: 126.6265338,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Ao98rcyyzPg?si=I4g3RupwVBFXj0iY",
        location: "센트럴플라자광장(3/23)",
        cont: "김포 기자회견",
      },
    ],
  },
  {
    id: "marker96",
    lat: 37.5381800276565,
    lng: 126.722617149353,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/SMt1v1vBb1c?si=6SM7UCychQym9VjY",
        location: "계산전통시장 - 경인교대역3번출구(3/23)",
        cont: "계양 게릴라",
      },
    ],
  },
  {
    id: "marker97",
    lat: 37.5093364,
    lng: 127.0859864,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/IJq82KCcN1s?si=Cq-gnII1Cnp2p_Yv",
        location: "송파 잠실새마을전통시장(3/24)",
        cont: "송파 거리인사",
      },
    ],
  },
  {
    id: "marker98",
    lat: 37.5087492,
    lng: 127.0840482,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/IJq82KCcN1s?si=Cq-gnII1Cnp2p_Yv",
        location: "석촌호수로12길 21(3/24)",
        cont: "송파 기자회견",
      },
    ],
  },
  {
    id: "marker99",
    lat: 37.4880163,
    lng: 127.1018486,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/VAYJ_6cwdiY?si=wVXzV_pJGWZbK07w",
        location: "강남 수서역(3/24)",
        cont: "수서역 거리인사",
      },
    ],
  },
  {
    id: "marker100",
    lat: 37.5055182,
    lng: 127.0070626,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/nkJVOShMyII?si=tk2OUZMYhci4mecB",
        location: "신반포로 194(3/24)",
        cont: "서초 거리인사",
      },
    ],
  },
  {
    id: "marker101",
    lat: 37.5058965,
    lng: 127.0050939,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/nkJVOShMyII?si=tk2OUZMYhci4mecB",
        location: "반포동 19-13(3/24)",
        cont: "서초 기자회견",
      },
    ],
  },
  {
    id: "marker102",
    lat: 37.500166,
    lng: 126.931578,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/dnYkOK2I-WY?si=sq3MVTtKU9MSxZ7E",
        location: "동작 성대시장(3/24)",
        cont: "동작 거리인사",
      },
    ],
  },
  {
    id: "marker103",
    lat: 37.4991265,
    lng: 126.9287614,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/dnYkOK2I-WY?si=sq3MVTtKU9MSxZ7E",
        location: "대방동 333-3(3/24)",
        cont: "동작 기자회견",
      },
    ],
  },
  {
    id: "marker104",
    lat: 37.49671,
    lng: 126.9048977,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/HDHEyUWwtSE?si=CVePGSWBnLL3vhB3",
        location: "영등포 우리시장(3/24)",
        cont: "영등포 거리인사",
      },
    ],
  },
  {
    id: "marker105",
    lat: 37.4963139,
    lng: 126.9053489,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/HDHEyUWwtSE?si=CVePGSWBnLL3vhB3",
        location: "도림로 64길 20(3/24)",
        cont: "영등포 기자회견",
      },
    ],
  },
  {
    id: "marker106",
    lat: 34.8931069,
    lng: 128.6094962,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/HCkrPlOxuh8?si=7bK53mDYk-bd01ZO",
        location: "장평로8길 32(3/25)",
        cont: "거제 삼성중공업 출근인사",
      },
    ],
  },
  {
    id: "marker107",
    lat: 35.2413361,
    lng: 128.6298634,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/6LMNelcecac?si=U0yWfqc0aU0PFdf7",
        location: "의창구 창원대로 7(3/25)",
        cont: "창원 경남 현장선대위 회의",
      },
    ],
  },
  {
    id: "marker108",
    lat: 35.2354849,
    lng: 128.6706779,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/OckY1K8AAGU?si=TSdYA2W-z-wzOOch",
        location: "반송약국(3/25)",
        cont: "창원 반송시장",
      },
    ],
  },
  {
    id: "marker109",
    lat: 35.2354849,
    lng: 128.6706779,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/OckY1K8AAGU?si=TSdYA2W-z-wzOOch",
        location: "반송약국(3/25)",
        cont: "창원 기자회견",
      },
    ],
  },
  {
    id: "marker110",
    lat: 35.1762122,
    lng: 128.8071657,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Xw5Hic5CdpY?si=EqQ4I_LYkGLwgf3A",
        location: "김해 율하카페거리 / 율하카페길 7(3/25)",
        cont: "김해 거리인사",
      },
    ],
  },
  {
    id: "marker111",
    lat: 35.2621521,
    lng: 128.8689364,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/yiVETPoLD9U?si=0uhTZ084wBvIKOxY",
        location: "삼계수리공원 내 광장(3/25)",
        cont: "김해 기자회견",
      },
    ],
  },
  {
    id: "marker112",
    lat: 35.3428094,
    lng: 129.0372093,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/wnFmZ2xp8s8?si=ezNTgFKgwyIMym3Z",
        location: "양산 남부시장 / 장터4길 17(3/25)",
        cont: "양산 거리인사",
      },
    ],
  },
  {
    id: "marker113",
    lat: 35.3429594,
    lng: 129.0356506,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/wnFmZ2xp8s8?si=ezNTgFKgwyIMym3Z",
        location: "kt플라자 양산점(3/25)",
        cont: "양산 기자회견",
      },
    ],
  },
  {
    id: "marker114",
    lat: 37.5607268,
    lng: 126.9637172,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/ibwpus_2IFo?si=xScyDcKYsSVz-Yjw",
        location: "충정로역 벙커1(3/26)",
        cont: "겸뉴공",
      },
    ],
  },
  {
    id: "marker115",
    lat: 37.557382,
    lng: 126.956083,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/O0A1t_oi97o?si=ezyFYBkBZ-sWbMdj",
        location: "아현역(3/26)",
        cont: "서대문역 출근인사",
      },
    ],
  },
  {
    id: "marker117",
    lat: 37.5066909,
    lng: 126.9607295,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/FwlZ5jHxjhQ?si=y-4eLk5L3wKAvP5H",
        location: "흑석로 102 중앙대병원(3/26)",
        cont: "동작을 지원유세",
      },
    ],
  },
  {
    id: "marker118",
    lat: 37.5133861,
    lng: 126.9439222,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/plX6mkEvamU?si=AL59QZtFgTQj2Jba",
        location: "노량진로 162(3/26)",
        cont: "동작갑 지원유세",
      },
    ],
  },
  {
    id: "marker119",
    lat: 37.541033,
    lng: 127.1443608,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/2hFiSIT0dSA?si=fiejT4yqRp5qzt2y",
        location: "길동시장(3/26)",
        cont: "강동을 지원유세",
      },
    ],
  },
  {
    id: "marker120",
    lat: 37.552316,
    lng: 127.129647,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/2hFiSIT0dSA?si=fiejT4yqRp5qzt2y",
        location: "암사종합시장(3/26)",
        cont: "강동갑  지원유세",
      },
    ],
  },
  {
    id: "marker121",
    lat: 37.2097769,
    lng: 127.4810494,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/DbR3qL7PC5w?si=UOeDHokWru9E-fzC",
        location: "이천터미널 맞은편 먹자골목(3/26)",
        cont: "이천 거리인사",
      },
    ],
  },
  {
    id: "marker122",
    lat: 36.987716,
    lng: 127.9287219,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/cH8CJhUMI-o?si=2EFnleNRpKbgsHRe",
        location: "국원대로 180(3/27)",
        cont: "더민주 더민련 합동선대위 및 총선비전공동선언식",
      },
    ],
  },
  {
    id: "marker123",
    lat: 36.9730813,
    lng: 127.9296472,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Urzsb5Eiai8?si=LAj8ldyvUJ7tjqPt",
        location: "충주 무학시장, 자유시장(3/27)",
        cont: "충주 거리인사",
      },
    ],
  },
  {
    id: "marker124",
    lat: 37.1390043,
    lng: 128.2128973,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Ad0RqM1R4ek?si=B1NVnLmWwfvI8RUm",
        location: "제천 동문시장, 내토전통시장(3/27)",
        cont: "제천 거리인사",
      },
    ],
  },
  {
    id: "marker125",
    lat: 36.7150519,
    lng: 127.4289534,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/e-cRBx6cT3A?si=_5nambB9_vbhbyse",
        location: "청주 청원구 오창상가 / 보담육풍 앞(3/27)",
        cont: "청주 거리인사",
      },
    ],
  },
  {
    id: "marker126",
    lat: 36.7113413999999,
    lng: 127.429328,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/e-cRBx6cT3A?si=_5nambB9_vbhbyse",
        location: "오창 중심대광장(3/27)",
        cont: "청주 기자회견",
      },
    ],
  },
  {
    id: "marker127",
    lat: 36.6372334,
    lng: 127.4890824,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/9VwEV-tK4Kk?si=tpe1rc6aP_Q8WwKo",
        location: "상당구 사직대로 375(3/27)",
        cont: "청주성안길 거리인사",
      },
    ],
  },
  {
    id: "marker128",
    lat: 36.6114961,
    lng: 127.4656091,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/o8IlhMBKmLQ?si=HM4ZocQIfvftEJ8x",
        location: "청주 서원구 원흥이마중길(3/27)",
        cont: "청주 거리인사",
      },
    ],
  },
  {
    id: "marker129",
    lat: 36.6415147,
    lng: 127.4304224,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/guzQWYsTWes?si=WR2_rnHjFgMPOTJO",
        location: "청주 흥덕구 복대동 먹자골목(3/27)",
        cont: "청주 거리인사",
      },
    ],
  },
  {
    id: "marker130",
    lat: 37.571594,
    lng: 126.7363805,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/WqmDnLHKEgo?si=a8zVaUt62A27Jn7V",
        location: "인천 계양역(3/28)",
        cont: "출근인사",
      },
    ],
  },
  {
    id: "marker131",
    lat: 37.571594,
    lng: 126.7363805,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/WqmDnLHKEgo?si=a8zVaUt62A27Jn7V",
        location: "인천 계양구 (3/28)",
        cont: "유세차 순회 (계양역>귤현동>동양동>계산4동)",
      },
    ],
  },
  {
    id: "marker132",
    lat: 37.5298837,
    lng: 126.9648019,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/xHQieNowNgo?si=xMA7UJorSjM6nWhZ",
        location: "용산역 광장(3/28)",
        cont: "서울 정권심판국민승리선대위출정식",
      },
    ],
  },
  {
    id: "marker133",
    lat: 37.5616887,
    lng: 127.0279152,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/tmNNk4blmLQ?si=JbrDt7FW9UNNjZuq",
        location: "왕십리역 남측 광장(3/28)",
        cont: "서울 중구성동갑 지지유세",
      },
    ],
  },
  {
    id: "marker134",
    lat: 37.5616887,
    lng: 127.0279152,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/tmNNk4blmLQ?si=JbrDt7FW9UNNjZuq",
        location: "서울 왕십리역(3/28)",
        cont: " 인근 거리인사",
      },
    ],
  },
  {
    id: "marker135",
    lat: 37.4838374,
    lng: 126.9723532,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/_WJU8jWrhfw?si=T5kgR8HVdkmtUIAX",
        location: "사당로 222(3/28)",
        cont: "서울 동작을 지지유세",
      },
    ],
  },
  {
    id: "marker136",
    lat: 37.4999855,
    lng: 126.9326052,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/0FlA6QVEQ3s?si=bavQbeat4LfWHHrO",
        location: "성대로 11(3/28)",
        cont: "서울 동작갑 지지유세",
      },
    ],
  },
  {
    id: "marker137",
    lat: 37.4903860999999,
    lng: 126.7236421,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "",
        location: "부평역 북부광장(3/28)",
        cont: "인천시당 총선 출정식",
      },
    ],
  },
  {
    id: "marker138",
    lat: 37.5277791,
    lng: 126.7338072,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/KKsHZbBpljo?si=OXwwalZ02YDkQbQV",
        location: "장제로 672(3/28)",
        cont: "인천 계양구 유세차 순회",
      },
    ],
  },
  {
    id: "marker139",
    lat: 37.5379472,
    lng: 126.7388571,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/KKsHZbBpljo?si=OXwwalZ02YDkQbQV",
        location: "계양문화로 33(3/29)",
        cont: "인천 계양구 도보인사",
      },
    ],
  },
  {
    id: "marker140",
    lat: 37.5179805,
    lng: 127.117733,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/dsFvRlQ_nME?si=J99zOzcki09wAK1_",
        location: "석촌호수 서호 수변무대(송파구 석촌호수로 191)(3/30)",
        cont: "송파을 송기호 후보 지지유세",
      },
    ],
  },
  {
    id: "marker141",
    lat: 37.5111992,
    lng: 127.1154303,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/19USbGD32dU?si=DDElxeqZ2fvAsFO0",
        location: "방이시장 안 kt대리점 앞(송파구 백제고분로 48길19)(3/30)",
        cont: "송파갑 조재희 후보 지지유세",
      },
    ],
  },
  {
    id: "marker142",
    lat: 37.4764108,
    lng: 127.1420412,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/BMMz4bSRmoA?si=fPsf3QjblCiaVPSv",
        location: "파리바게뜨 위례아이파크점 앞(송파구 위례광장로 136)(3/30)",
        cont: "송파병 남인순 후보 지지유세",
      },
    ],
  },
  {
    id: "marker143",
    lat: 37.5567754,
    lng: 127.168045,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/P4vbNAp2CLQ?si=Rhe2KD4W04NkeutS",
        location: "상일동역 5번 출구 앞(강동구 상일동 519-3)(3/30)",
        cont: "강동갑 진선미 후보 지지유세",
      },
    ],
  },
  {
    id: "marker144",
    lat: 37.5387882,
    lng: 127.0711369,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/rKa0QkEoBjY?si=vYlbJXWZgQaIZnpX",
        location: "건대입구 롯데백 화점 앞(광진구 능동로 92)(3/30)",
        cont: "광진을 고민정 후보 지지유세",
      },
    ],
  },
  {
    id: "marker145",
    lat: 37.5641401,
    lng: 127.0198207,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/ZgBODgHoxWk?si=nD1Bb78fKz3iKIJG",
        location: "백학시장 앞명동호프(중구 다산로42길 65)(3/30)",
        cont: "중구성동을 박성준 후보 지지유세",
      },
    ],
  },
  {
    id: "marker146",
    lat: 37.5502287,
    lng: 126.9775118,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/ik7jKpiDOf0?si=2j1hz1zMKLp5asbg",
        location: "매머드익스프레스 후암시장점(용산구 후암로 48)(3/30)",
        cont: "용산구 강태웅 후보 지지유세",
      },
    ],
  },
  {
    id: "marker147",
    lat: 37.5002841,
    lng: 126.9166308,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/rvMA6qq7UTg?si=rUKnRJgaHBkpACkJ",
        location: "보라매 미소약국 앞(영등포구 신풍로 93 메트하임 1층)(3/30)",
        cont: "영등포을 김민석 후보 지지유세",
      },
    ],
  },
  {
    id: "marker148",
    lat: 37.5002469,
    lng: 126.9166177,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/rvMA6qq7UTg?si=rUKnRJgaHBkpACkJ",
        location: "영등포구 신풍로 93 메트하임 상가 앞(3/30)",
        cont: "서울 현장기자회견",
      },
    ],
  },
  {
    id: "marker149",
    lat: 37.5451941,
    lng: 126.9471063,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/0gUW0Dj_BOk?si=V6L2-FkfFZtHqz-S",
        location:
          "공덕파크자이201동 주차장 앞 경의선 숲길(마포구 백범로 152)(3/30)",
        cont: "마포갑 이지은 후보 지지유세",
      },
    ],
  },
  {
    id: "marker150",
    lat: 37.5559992,
    lng: 126.9358069,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/_iVzUoXYJI4?si=29PNTbcvCepLFkky",
        location: "현대백화점 신촌점 정문 앞(서대문구 신촌로 83)(3/30)",
        cont: "서대문갑 김동아 후보 지지유세",
      },
    ],
  },
  {
    id: "marker151",
    lat: 37.5458937,
    lng: 126.7273474,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "",
        location: "인천 계양 성당?(3/31)",
        cont: " 부활절 예배, 부활절 미사",
      },
    ],
  },
  {
    id: "marker152",
    lat: 37.5357306,
    lng: 126.7445609,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/SLJDgrm6Pd8?si=fQhzfS9sEOMLYbb2",
        location: "살라리로2번길 15(3/31)",
        cont: "계산4동 유세차 순회",
      },
    ],
  },
  {
    id: "marker153",
    lat: 37.5341076,
    lng: 126.7381035,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "",
        location: "도두리로33(3/31)",
        cont: "인천 지역단체 지지선언 ",
      },
    ],
  },
  {
    id: "marker154",
    lat: 37.5290909,
    lng: 126.7842285,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "https://youtu.be/XLLnTwB--Qk?si=8fwDzZFvpRXT7uwb",
        location: "경기 부천시 오정구 오정로 233(4/1)",
        cont: "계양을 후보자 토론회 녹화",
      },
    ],
  },
  {
    id: "marker155",
    lat: 37.4573713,
    lng: 126.6493088,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/8y4rIuthUsg?si=pDWnuq-Hcsqm5Uic",
        location: "용현시장 정문 맞은편 (미추홀구 독배로 423)(4/1)",
        cont: "동구미추홀구 남영희 지지유세",
      },
    ],
  },
  {
    id: "marker156",
    lat: 37.4882909,
    lng: 126.5622785,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/vYEthE5-vPU?si=CuzJcCex8uxNMcTU",
        location: "영종하늘도시 별빛광장(중구 중산동 1884-5)(4/1)",
        cont: "중구강화군용진군 조택상 지지유세",
      },
    ],
  },
  {
    id: "marker157",
    lat: 37.538806151154,
    lng: 126.738053984314,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/5psgw2sLVQU?si=C7zkfrnZ8dS28t5p",
        location: "계양먹거리골목(4/1)",
        cont: "계양을 거리유세",
      },
    ],
  },
  {
    id: "marker159",
    lat: 37.484876,
    lng: 126.970673,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Di7JWPM6rlk?si=cHHvrPqQcrjzeTS_",
        location: "남성역(4/2)",
        cont: "류삼영 지지유세",
      },
    ],
  },
  {
    id: "marker160",
    lat: 33.4516599,
    lng: 126.618983,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "https://youtu.be/xQfytogPWOw?si=_vIaFfnmExA6udSa",
        location: "제주4.3평화공원(4/3)",
        cont: "제주4.3추념식",
      },
    ],
  },
  {
    id: "marker161",
    lat: 35.2166758,
    lng: 128.6920639,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/4sukUGGl_P0?si=QUf9NoNuYsFubLG8",
        location: "원이대로 774(4/3)",
        cont: "경남 창원시성산구 허성무 지지유세",
      },
    ],
  },
  {
    id: "marker162",
    lat: 35.2575362,
    lng: 128.6375315,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/KoUPRJw3iYY?si=vupXnXMg4bxfuqC8",
        location: "원이대로 69번길 20-1(4/3)",
        cont: "경남 창원시의창구 김지수 지지유세",
      },
    ],
  },
  {
    id: "marker163",
    lat: 35.1634095,
    lng: 128.9795825,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/FPHyS1ks5Ds?si=G9uLFKpg8AniOKaC",
        location: "광장로 25(4/3)",
        cont: "부산 사상구 배재정 지지유세",
      },
    ],
  },
  {
    id: "marker164",
    lat: 35.153323,
    lng: 129.020422,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/FTL7_PiIfAg?si=bwECz84X5MaPHGDI",
        location: "개금역 2번출구 인근(4/3)",
        cont: "부산진구을 이현 지지유세",
      },
    ],
  },
  {
    id: "marker165",
    lat: 35.1542549,
    lng: 129.0597433,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/udrL13dP5Bk?si=KaqZqfwfcTX4-WGy",
        location: "신천대로50번길 79(4/3)",
        cont: "부산 서면 집중유세",
      },
    ],
  },
  {
    id: "marker166",
    lat: 35.0914612,
    lng: 129.042386,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/mG9SfUSWn9Y?si=em7aSIiM43Sb2Ejg",
        location: "롯데하이마트 영도점(영도구 태종로 105)(4/4)",
        cont: "부산 중구영도구 박영미 후보 지지유세",
      },
    ],
  },
  {
    id: "marker167",
    lat: 35.1153165,
    lng: 129.0403623,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/0urWSyp4GtI?si=Zwhbhf0NXa0LeOZ7",
        location: "부산역 광장(동구 초량동 1202(4/4)",
        cont: "부산시 국회의원 후보자 사전투표 독려 퍼포먼스",
      },
    ],
  },
  {
    id: "marker168",
    lat: 35.1645155,
    lng: 129.0540371,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/DeVncZQcmU4?si=ye0v3wDe6EPycw7X",
        location: "부산진구 새싹로 84(4/4)",
        cont: "부산진구갑 서은숙 후보 지지유세",
      },
    ],
  },
  {
    id: "marker169",
    lat: 35.162213,
    lng: 129.113731,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/lh8zvm5_lV8?si=Dy7nFeT19vuAuafJ",
        location: "올리브영 부산수영로점 옆(수영구 수영로 631)(4/4)",
        cont: "부산 수영구 유동철 후보 지지유세",
      },
    ],
  },
  {
    id: "marker170",
    lat: 35.2427484,
    lng: 129.2169898,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/JgvpHswy5YI?si=rldWsk26iVBGHhoZ",
        location: "기장교회 앞(기장군 기장읍 차성동로 58)(4/4)",
        cont: "부산 기장군 최택용 후보 지지유세",
      },
    ],
  },
  {
    id: "marker171",
    lat: 35.5274347,
    lng: 129.3213033,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/hBugdMvxbCk?si=Ds-QVhfOGhyHJLhq",
        location: "BNK경남은행 수암지점 앞(남구 수암로 130)(4/4)",
        cont: "울산 남구을 박성진 후보 지지유세",
      },
    ],
  },
  {
    id: "marker172",
    lat: 35.5430288,
    lng: 129.3112335,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/e5LBrGTHZ0U?si=3SnxowzxpCcDQwb7",
        location: "IBK기업은행 울산지점(남구 중앙로 248)(4/4)",
        cont: "울산 남구갑 전은수 후보 지지유세",
      },
    ],
  },
  {
    id: "marker173",
    lat: 35.4986204,
    lng: 129.420796,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink: "https://youtu.be/Umf61QDnd5I?si=-0KJyocGuxzh0L3Q",
        location: "동구 양지5길 72(4/4)",
        cont: "울산 동구 김태선 후보 지지유세",
      },
    ],
  },
  {
    id: "marker174",
    lat: 35.5736265,
    lng: 129.2413774,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/WgkwmKjTZXQ?si=iqrtRr3BP-2Jb4nD",
        location: "범서읍 구영로 100(4/4)",
        cont: "울주군 이선호 후보 지지유세",
      },
    ],
  },
  {
    id: "marker175",
    lat: 35.8790277,
    lng: 128.6279314,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/myr6QnUSmro?si=_Khf_YNqKnSLYsxA",
        location: "동대구역 광장 (동대구로 550)(4/4)",
        cont: "대구시 후보 집중유세",
      },
    ],
  },
  {
    id: "marker176",
    lat: 36.3267462,
    lng: 127.420376,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/i2EZRFGos7U?si=l8Pn2mFaFzYGy0Op",
        location: "대전 중구 은행선회동 사전투표소(중구 중앙로79번길 48)(4/5)",
        cont: "제22대 국회의원선거 사전투표",
      },
    ],
  },
  {
    id: "marker177",
    lat: 36.3290264,
    lng: 127.4276548,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/qVnHbT_RZhg?si=DT4almtIpOEPjr2c",
        location: "으능정이문화거리 이안경원 앞(중구 중앙로 164)(4/5)",
        cont: "대전 중구 박용갑 후보 지지유세",
      },
    ],
  },
  {
    id: "marker178",
    lat: 36.3027484,
    lng: 127.5683113,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/pTB2BdSO4_s?si=luWeK1gulllLUviP",
        location: "옥천공설시장 정문(옥천군 옥천읍 삼금로 49-4)(4/5)",
        cont: "옥천공설시장 방문",
      },
    ],
  },
  {
    id: "marker179",
    lat: 36.3016578,
    lng: 127.5681888,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/27n1Vttt9fY?si=qIwd6se2vxG9pQWP",
        location: "옥천군 옥천읍 금장로 35(4/5)",
        cont: "보은군옥천군영동군괴산군 이재한 후보 지지유세",
      },
    ],
  },
  {
    id: "marker180",
    lat: 36.6363828,
    lng: 127.4837188,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/yMEOtdX3OLk?si=QjewZGYSSe5LB5s1",
        location: "청주 무심천 서문교(서원구 사직동 123-7)(4/5)",
        cont: "청주 서원구 이광희 후보 지지유세",
      },
    ],
  },
  {
    id: "marker181",
    lat: 36.4709356,
    lng: 127.1377943,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/u5milY_JsmA?si=SWcj7QWY7szlixf2",
        location: "공주대 후문 삼거리(공주시 공주대학로 67)(4/5)",
        cont: "공주시부여군청양군 박수현 후보 지지유세",
      },
    ],
  },
  {
    id: "marker182",
    lat: 36.3511253,
    lng: 126.5924143,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/SR3FDu6fs8U?si=7ifjU8JtoL5kHzOa",
        location: "충남 보령시 번영로 34(4/5)",
        cont: "보령시서천군 나소열 후보 지지유세",
      },
    ],
  },
  {
    id: "marker183",
    lat: 36.8195465,
    lng: 127.156151,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/x12kBCbh5RA?si=7VgVu5M3EGPLTQJK",
        location: "신세계백화점 천안아산점 앞(천안시 동남구 만남로 43)(4/5)",
        cont: "천안시갑 문진석 후보 지지유세",
      },
    ],
  },
  {
    id: "marker184",
    lat: 37.5481489,
    lng: 127.0210214,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Do8L4uEL-d0?si=i_dUr7ejGT06OBgP",
        location:
          "우리은행 금호동지점 길 건너편 교통섬(성동구 장터길 46 건너편)(4/6)",
        cont: "중구성동구을 박성준 지지유세",
      },
    ],
  },
  {
    id: "marker185",
    lat: 37.3230131,
    lng: 127.095985,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/_Jud7LdqMSw?si=i-onfYYu1h7GIdCc",
        location:
          "LG유플러스 풍덕천1동 풍덕천사거리점 앞(용인시 수지구 풍덕천로 128)(4/6)",
        cont: "용인시병 부승찬 후보 지지유세",
      },
    ],
  },
  {
    id: "marker186",
    lat: 37.2805541,
    lng: 127.4440487,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/TaTKiWkXjZ8?si=8wgOLrhYEe3oIqdi",
        location: "중앙로문화의거리(이천시 어재연로37번길 29)(4/6)",
        cont: "이천시 엄태준 후보 지지유세",
      },
    ],
  },
  {
    id: "marker187",
    lat: 37.4830456,
    lng: 127.4977914,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/B1Mn6IcTJ6o?si=xHm37nVZbKoPt-kq",
        location:
          "양평실내탁구장 내 주차장 양평구 양평읍 양평체육공원길 23)(4/6)",
        cont: "여주시양평군 최재관 후보 지지유세",
      },
    ],
  },
  {
    id: "marker188",
    lat: 37.8280842,
    lng: 127.1457707,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/gWY7GyEf5hI?si=Rm2ah84XZ5GFJY8m",
        location: "포천시 소흘읍 송우로 28-1(4/6)",
        cont: "포천시가평군 박윤국 후보 지지유세",
      },
    ],
  },
  {
    id: "marker189",
    lat: 37.892001,
    lng: 127.0563187,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/aFec4ymERrQ?si=0ivDzubfl0w7AjCE",
        location: "지행역 4번 출구 앞(동두천시 지행동 481-8)(4/6)",
        cont: "동두천시양주시연천군을 남병근 후보 지지유세",
      },
    ],
  },
  {
    id: "marker190",
    lat: 37.6452695,
    lng: 126.6279543,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/IjyvbY1rk2g?si=S40OwKNZDAeqvQqb",
        location: "구래역 1번 출구 앞 광장(김포시 김포한강7로 87)(4/6)",
        cont: "김포시을 박상혁 후보 지지유세",
      },
    ],
  },
  {
    id: "marker191",
    lat: 37.538806151154,
    lng: 126.738053984314,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/u0Cd-Csqg0U?feature=shared&t=575",
        location: "계양먹거리골목(4/6)",
        cont: "게릴라 계양을 거리유세",
      },
    ],
  },
  {
    id: "marker192",
    lat: 37.54326,
    lng: 126.728533,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/nq1KCOj2cuQ?si=0nsLOSDJDOI93Pol",
        location: "계산역>계양산 인근(4/7)",
        cont: "계양을 거리인사",
      },
    ],
  },
  {
    id: "marker193",
    lat: 37.5411282,
    lng: 126.7451453,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/YEZYkzi8BpM?feature=shared&t=7",
        location: "계양 서부간선수로 산책로~계양먹자골목(4/7)",
        cont: "계양을 유세차량 순회",
      },
    ],
  },
  {
    id: "marker194",
    lat: 37.4847785,
    lng: 127.0333843,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/72q10YKckEc?si=iBzPXafFTblr1sh4",
        location: "양재역 12번 출구 건너편(서초구 서초동 1366-9)(4/7)",
        cont: "서울 서초구을 홍익표 후보 지지유세",
      },
    ],
  },
  {
    id: "marker195",
    lat: 37.4880163,
    lng: 127.1018486,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Di6R30lEt_4?si=M1rbcbME0wUEn4Zd",
        location: "수서역 3번 출구 앞(강남구 수서동 713)(4/7)",
        cont: "서울 강남구을 강청희 후보 지지유세",
      },
    ],
  },
  {
    id: "marker196",
    lat: 37.5111913,
    lng: 127.1049844,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/zUKJqGUkFK4?si=PBdXgz9H76pXrbQT",
        location:
          "석촌호수 동호 송파관광정보센터 앞(송파구 송파나루길 206)(4/7)",
        cont: "서울 송파구갑 조재희 후보 지지유세",
      },
    ],
  },
  {
    id: "marker197",
    lat: 37.5035941,
    lng: 127.1036658,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/dvpk_BT2D0A?si=5esEF-unRQkj6HMB",
        location: "석촌동 주민센터 앞(송파구 백제고분로37길 16)(4/7)",
        cont: "서울 송파구을 송기호 후보 지지유세",
      },
    ],
  },
  {
    id: "marker198",
    lat: 37.5020592,
    lng: 127.1374675,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/KcLlCXxTUnA?si=Av0_PAykCJ-TM4R_",
        location: "성내천물빛광장(송파구 동남로 297)(4/7)",
        cont: "서울 송파구병 남인순 후보 지지유세",
      },
    ],
  },
  {
    id: "marker199",
    lat: 37.4800931,
    lng: 127.1484011,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/oDmljk5UAIk?si=i2W7XQPV-ByyTPMU",
        location: "하남위례스타필드시티 앞(하남시 위례대로 200)(4/7)",
        cont: "서울 하남시갑 추미애 후보 지지유세",
      },
    ],
  },
  {
    id: "marker200",
    lat: 37.5546516,
    lng: 127.1557517,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/zLYPwukp0Pk?si=nX8ycDd887pQsZzM",
        location: "이마트 명일점 앞(강동구 고덕로 276)(4/7)",
        cont: "서울 강동구갑 진선미 후보 지지유세",
      },
    ],
  },
  {
    id: "marker201",
    lat: 37.5443789,
    lng: 127.1264173,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/y90P7f1Mvf8?si=oQl7FR-GjS7qIwBa",
        location: "천호공원(강동구 올림픽로 702)(4/7)",
        cont: "서울 강동구을 이해식 후보 지지유세",
      },
    ],
  },
  {
    id: "marker202",
    lat: 37.5357818,
    lng: 126.7353988,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/YEZYkzi8BpM?si=uxLOeM_qcaO2jKU1",
        location: "계양문화로 53번길(4/7)",
        cont: "게릴라 계양을 거리유세",
      },
    ],
  },
  {
    id: "marker203",
    lat: 37.545075,
    lng: 126.738653,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/GNHD9nz8oHg?si=ttr9Tbu_wlrj4mw1",
        location: "계양구 일대, 임학역(4/8)",
        cont: "계양구을 출근 인사",
      },
    ],
  },
  {
    id: "marker204",
    lat: 37.5294223,
    lng: 126.9194817,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/qS9pRPwaumM?si=M24mEDBrh6Dd-RDn",
        location: "중앙당사 4층 대회의실(4/8)",
        cont: "제11차 중앙선거대책위원회의",
      },
    ],
  },
  {
    id: "marker205",
    lat: 37.4883121,
    lng: 126.9819352,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/k4zbhkB8GGo?si=EuEoNpfiCYSo-Z4R",
        location: "남성사계시장 동광약국 앞 (동작대로 129)(4/8)",
        cont: "서울 동작구을 류삼영 후보 지지유세",
      },
    ],
  },
  {
    id: "marker206",
    lat: 37.4963538,
    lng: 126.9572222,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/LFwiF4G3m4U?si=F4t9-JteNb3PMH8t",
        location: "숭실대학교 정문 앞 (상도로 369)(4/8)",
        cont: "숭실대학교 도보인사",
      },
    ],
  },
  {
    id: "marker207",
    lat: 37.5223286,
    lng: 126.9263482,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/QNXkAy2iuzE?si=MyjJpk6qmPqUTPUQ",
        location: "여의도우체국 앞 (여의나루로 60)(4/8)",
        cont: "영등포구을 김민석 후보 지지유세",
      },
    ],
  },
  {
    id: "marker208",
    lat: 37.5777967,
    lng: 127.0376748,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/g25B2IleLzE?si=8d01QZax9XW4R2Lq",
        location: "동의보감타워 앞 (왕산로 128)(4/8)",
        cont: "동대문구갑 안규백 후보 지지유세",
      },
    ],
  },
  {
    id: "marker209",
    lat: 37.5738865,
    lng: 127.0161358,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/c6viG0DpMro?si=ztuuyQNfsJqNKo-V",
        location: "다이소 동묘점 앞 (종로 34칠 롯데캐슬천지인)(4/8)",
        cont: "종로구 곽상언 후보 지지유세",
      },
    ],
  },
  {
    id: "marker210",
    lat: 37.5554105,
    lng: 127.013101,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Cek0Vf77fbQ?si=ryJuouK44HJWX4Kn",
        location: "신일교회 앞 (동호로10길 27)(4/8)",
        cont: "중구성동구을 박성준 후보 지지유세",
      },
    ],
  },
  {
    id: "marker211",
    lat: 37.5885776,
    lng: 126.9446757,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/8YNREdewGf0?si=9tfsXMWy8rOPncX9",
        location: "홍제역 2번출구 앞(홍제동 161-11)(4/8)",
        cont: "서대문구갑 김동아 후보 지지유세",
      },
    ],
  },
  {
    id: "marker212",
    lat: 37.5268539,
    lng: 126.8750349,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/-4wGWaBGyMM?si=Gqmh5Joms7SqqOxc",
        location: "현대백화점 목동점 후문 앞 (목동동로 257)(4/8)",
        cont: "양천구갑 황희 후보 지지유세",
      },
    ],
  },
  {
    id: "marker213",
    lat: 37.4488123,
    lng: 126.6498206,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Xp6IyKuzewE?si=X_oPIaog_g847gNF",
        location: "인하대역 4번출구 (용현동 672-3)(4/8)",
        cont: "동구미추홀구을 남영희 후보 지지유세",
      },
    ],
  },
  {
    id: "marker214",
    lat: 37.4124178,
    lng: 126.6749887,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/WiH-RjO-k0s?si=xwiDrwuTY0Op9jho",
        location: "동춘근린공원 (먼우금로 107)(4/8)",
        cont: "연수갑 박찬대 후보 지지유세",
      },
    ],
  },
  {
    id: "marker215",
    lat: 37.545075,
    lng: 126.738653,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/oyU9EhtCNS0?si=M4rboHBH6wE3Zfm0",
        location: "임학역(4/8)",
        cont: "계양구을 거리 인사",
      },
    ],
  },
  {
    id: "marker216",
    lat: 37.571594,
    lng: 126.7363805,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/g0NF0kcRC28?si=Ovd344o8_kWT0mdk",
        location: "계양구 일대(4/9)",
        cont: "계양구을 출근 인사",
      },
    ],
  },
  {
    id: "marker217",
    lat: 37.4970107,
    lng: 127.0114103,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/kiPzI4t41mE?si=as-e_cL9--YgPIys",
        location: "서울중앙지방법원 서관 (서초중앙로 157)(4/9)",
        cont: "기자회견",
      },
      // {
      //   youtubeLink:
      //     "https://www.youtube.com/live/N3VHvxoEVsk?si=vb_xecFp_Zsk_iKP",
      //   location: "휴정중 차 안에서(4/9)",
      //   cont: "틈새 라방",
      // },
    ],
  },
  {
    id: "marker218",
    lat: 37.529557,
    lng: 126.9641567,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/aK7fhRw_S70?si=uHu1CnBvpz5pVr8p",
        location: "용산역 광장 (한강대로21나길 17)(4/9)",
        cont: "정권심판,국민승리 총력유세",
      },
    ],
  },
  {
    id: "marker219",
    lat: 37.5394439,
    lng: 126.7362366,
    imageName: "marker-icon01",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/XiICDhlL0XQ?si=r25VFreDU3yYQhJP",
        location: "계양구 일대(4/9)",
        cont: "계양을 집중유세 및 거리인사",
      },
    ],
  },
  {
    id: "marker220",
    lat: 37.452589,
    lng: 126.6646603,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/58i-3Hl0YTk?si=8hRcrXBa1q7yiS4V",
        location: "인천동구미추홀구을 남영희(3/28)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker221",
    lat: 37.5056205,
    lng: 127.1152992,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/nS8hzdnKGos?si=7Mj2tD0GcS2cE1ae",
        location: "서울송파을 송기호(3/29)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker222",
    lat: 37.8921788908933,
    lng: 127.389989249725,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/AZyPmRCj-l4?si=KypHUMndKJR6AXQM",
        location: "경기포천가평 박윤국(3/29)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker223",
    lat: 35.1580273,
    lng: 128.9865896,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/hot987zUsnE?si=I3MFgSSnf3ccFQccc",
        location: "부산사상구 배재정(3/30)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker224",
    lat: 37.7091295,
    lng: 128.8324462,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/465fHiN2mGI?si=OKXNc47WDd00ZUxW",
        location: "강원강릉 김중남(3/30)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker225",
    lat: 35.5150401,
    lng: 129.328914,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/yIWojXv57JY?si=3uto6TfRgxFFsvX0",
        location: "울산남구갑 전은수(3/31)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker226",
    lat: 36.198887019352,
    lng: 126.629106511355,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/_xpZkhhNG7w?si=qrpq6tApl6Nu6A1A",
        location: "충남보령서천 나소열(4/1)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker227",
    lat: 35.1938469,
    lng: 129.1536102,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/d5W4Op5LP2g?si=iYKpZ3POLCWPJIH4",
        location: "부산해운대갑 홍순헌(4/1)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker228",
    lat: 37.9934240618685,
    lng: 127.037405015039,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/I3BdNQNX5gA?si=D8Ao0OfY1ICOoGPL",
        location: "경기동두천연천 남병근(4/2)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker229",
    lat: 35.205153,
    lng: 128.1297905,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/L8_1z3vUrEU?si=gjdL_yPrpe1_bUtD",
        location: "경남진주갑 갈상돈(4/3)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker230",
    lat: 37.2938155,
    lng: 127.137319,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/raVLw6eFWhk?si=IwAH0j5VMZqfX-PT",
        location: "서울중구성동을 박성준(4/3)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker231",
    lat: 37.3024585,
    lng: 127.6157502,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/aKaDpu29W4Y?si=gR0Um9Z7oqjk7cJ7",
        location: "경기여주양평 최재관(4/4)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker232",
    lat: 35.2322317,
    lng: 128.5364256,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/Sm65uHca0q0?si=QPGpZ6VGjzPbZmt6",
        location: "경남창원마산회원 송순호(4/4)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker233",
    lat: 35.0899401,
    lng: 128.9744881,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/wjVE8OSP0zQ?si=tj2ZhWmpx8-HaY01",
        location: "부산사하을 이재성(4/4)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker234",
    lat: 37.4966645,
    lng: 127.0629804,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/5Dx2sOsnbC0?si=_5zVAkw-sDWtVkTd",
        location: "서울강남을 강청희(4/5)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker235",
    lat: 37.035033,
    lng: 127.3027301,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/xT-iLn3lQfs?si=SeO6WpV_NGgTUMDg",
        location: "경기안성 윤종군(4/5)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker236",
    lat: 37.4988794,
    lng: 126.9516345,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/9hAmdqMpXWU?si=7tBV2HZJ-AdEkKIL",
        location: "동작을 지인찾기(4/5)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker237",
    lat: 36.6540137,
    lng: 128.4224359,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/dWWveIkg3_c?si=CAgABxFjvbDElb5c",
        location: "경북안동예천 김상우(4/6)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker238",
    lat: 37.7091295,
    lng: 128.83328595945628,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/V1OuXVGaZXM?si=E6N65mjJKcirM8zb",
        location: "강원강릉 김중남, 경남진주갑 갈상돈(4/8)",
        cont: "원격지원",
      },
    ],
  },
  {
    id: "marker239",
    lat: 35.205153,
    lng: 128.1297905,
    imageName: "marker-icon02",
    popContents: [
      {
        youtubeLink:
          "https://www.youtube.com/live/V1OuXVGaZXM?si=E6N65mjJKcirM8zb",
        location: "강원강릉 김중남, 경남진주갑 갈상돈(4/8)",
        cont: "원격지원",
      },
    ],
  },
]; // 마커를 그릴 데이터
